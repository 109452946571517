import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ByggaHusPage = () => (
  <Layout>
    <SEO title="Bygga hus" />
    <h1>Bygga hus</h1>
    <p>Wow! Du hittade hit.</p>
  </Layout>
)

export default ByggaHusPage
